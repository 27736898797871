import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
import Legal from "../views/Legal.vue";
import Support from "../views/Support.vue";
import SupportDocs from "../views/SupportDocs.vue";
import ZoomPage from "../views/ZoomPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal,
  },
  {
    path: "/software",
    name: "Support",
    component: Support,
  },
  {
    path: "/docs",
    name: "SupportDocs",
    component: SupportDocs,
  },
  {
    path: "/zoom-instant-meeting",
    name: "ZoomPage",
    component: ZoomPage,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div class="about">
    <div class="container" style="text-align: left; padding-left: 25px; padding-right: 25px">
      <section id="id-a66e84aba5454a8fbd310be24ae74343" class="free-form-component-wrapper  theme-white-bg-dark-text py-4">
        <h1>SMART TeamWorks</h1>

        <h3>About SMART TeamWorks</h3>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/what-is-smart-teamworks.cshtml">What is SMART TeamWorks</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/version-comparison.cshtml">Comparing editions</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/server-features.cshtml">About SMART TeamWorks Server</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/about-trials-subscriptions.cshtml">About trials and subscriptions</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/computer-reqs.cshtml">System requirements</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/languages.cshtml">Supported languages</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/supported-smart-hardware.cshtml">Supported SMART Hardware</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/about/download-install.cshtml">Downloading and installing</a></h5>
        <h3><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/release-notes/default.cshtml">Release notes</a></h3>
        <h3><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/using/getting-started/default.cshtml">Getting started</a></h3>
        <h3>Using the launcher</h3>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/using/launcher/about-the-launcher.cshtml">About the launcher's interface</a></h5>
        <h5><a href ="https://support.smarttech.com/docs/software/smart-teamworks/en/using/launcher/start-end-meetings.cshtml">Starting and ending meetings</a></h5>
        <h3><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/screen-sharing/default.cshtml">Screen sharing</a></h3>
        <h3><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/recording-meetings/default.cshtml">Recording meetings</a></h3>
        <h3>Using the whiteboard</h3>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/about-the-interface.cshtml">About the toolbar</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/adding-managing-pages.cshtml">Adding and managing pages</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/using-business-templates.cshtml">Using background templates</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/writing-drawing.cshtml">Writing and drawing</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/adding-text.cshtml">Adding text</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/inserting-files.cshtml">Inserting and working with files</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/inserting-web-browsers.cshtml">Inserting and using web browsers</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/inserting-with-sticky-notes.cshtml">Inserting and using sticky notes</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/move-resize-rotate-objects.cshtml">Moving, resizing, rotating, and locking objects</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/taking-screen-captures.cshtml">Taking screen shots</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/using-voice-commands.cshtml">Using voice commands</a></h5>
            <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/ending-meeting-create-recaps.cshtml">Ending meetings and creating recaps</a></h5>
        <h3><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/whiteboard/manage-remote-participants.cshtml">Starting a whiteboard meeting and managing participants</a></h3>
        <h3><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/customizing-interface/default.cshtml">Customizing the interface</a></h3>
        <h3><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using/close-teamworks.cshtml">Access the desktop and close SMART TeamWorks</a></h3>
        <h3>Using the Contributor apps</h3>
        <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using-apps/contributor-pc.cshtml">Using the Contributor app for Windows 10</a></h5>
        <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using-apps/contributor-ios.cshtml">Using the Contributor app for iOS</a></h5>
        <h5><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/using-apps/contributor-android.cshtml">Using the Contributor app for Android</a></h5>
        <h3><a href="https://support.smarttech.com/docs/software/smart-teamworks/en/troubleshooting/default.cshtml">Troubleshooting</a></h3>

      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// import VueAccordion from '@ztrehagem/vue-accordion';

export default Vue.extend({
  components: {
    // VueAccordion
  },
  data() {
    return {
      expanded: false
    };
  }
});
</script>